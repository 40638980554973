
  import { defineComponent, ref, onMounted, computed, reactive } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import PackagesFilter from '@/components/dropdown/PackagesFilter.vue';

  export default defineComponent({
    name: 'merchant-packages-listing',
    components: {
      Datatable,
      PackagesFilter,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('merchantPackages'), [
          translate('packages'),
        ]);
        loading.value = true;
        await store.dispatch(Actions.GET_PACKAGES, 'merchant');
        loading.value = false;

        await store.dispatch(Actions.EXPORT_PACKAGES, 'merchant');
      });

      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('Shipment ID'),
          key: 'shipmentTrackNumber',
          sortable: true,
        },
        {
          name: translate('Package ID'),
          key: 'trackNumber',
          sortable: true,
        },
        {
          name: translate('customer name'),
          key: 'name',
          sortable: true,
        },
        {
          name: translate('recipientPhoneNumber'),
          key: 'recipientPhoneNumber',
          sortable: true,
        },
        {
          name: translate('city'),
          key: 'areaName',
          sortable: true,
        },
        {
          name: translate('passport Number'),
          key: 'passportNumber',
          sortable: true,
        },
        {
          name: translate('office Name'),
          key: 'officeName',
          sortable: true,
        },
        {
          name: translate('Driver Name'),
          key: 'driverName',
          sortable: true,
        },
        {
          name: translate('date'),
          key: 'createdAt',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'status',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);

      const formatDate = (date) => {
        return new Date(date).toISOString().slice(0, 10);
      };

      const excelHeader = reactive({
        'Shipment ID': 'shipmentTrackNumber',
        'Package ID': 'trackNumber',
        'Customer Name': 'name',
        'Phone Number': 'recipientPhoneNumber',
        City: 'areaName',
        'Driver Name': 'driverName',
        'Number Of Items': 'numberOfProducts',
        'Office Name': 'officeName',
        Status: 'internalStatus',
        Date: {
          field: 'createdAt',
          callback: (value) => {
            return formatDate(value);
          },
        },
      });

      const deletePackages = async (packages) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_PACKAGE, packages.id);
          await store.dispatch(Actions.GET_PACKAGES, 'merchant');
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const moveToShipment = (id) => {
        router.push({ name: 'shipment-editing', params: { id } });
      };

      const overView = (id) => {
        router.push({ name: 'merchant-packages-overview', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_PACKAGE_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_PACKAGES, 'merchant');
        loading.value = false;
      };

      const onTypeChange = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_PACKAGES_FILTER, value);
        await store.dispatch(Actions.GET_PACKAGES, 'merchant');
        await store.dispatch(Actions.EXPORT_PACKAGES, 'merchant');
        setTimeout(() => {
          loading.value = false;
        }, 4000);

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        }
      };

      const onReset = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_PACKAGES_FILTER, value);
        await store.dispatch(Actions.GET_PACKAGES, 'merchant');
        await store.dispatch(Actions.EXPORT_PACKAGES, 'merchant');
        loading.value = false;
      };

      return {
        tableData: computed(() => store.getters.packagesList),
        tableHeader,
        moveToShipment,
        deletePackages,
        onReset,
        onTypeChange,
        translate,
        totalItems: computed(() => store.getters.packagesListCount),
        pageChanged,
        goTo,
        overView,
        loading,
        can,
        excelHeader,
        excelData: computed(() => store.getters.exportPackagesList),
      };
    },
  });
